import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { CustomersRepository } from '../../api/CustomersRepository'
import { TaxRepository } from '../../api/TaxRepository'
import Email from '../components/Email'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
    addTax,
    setError,
    setSuccess,
} from '../../redux/state-slices/GlobalNotificationSlice'
import Loading from '../../shared-components/Loading'
import TaxModal from '../invoices/components/TaxModal'
import {
    validateAddress,
    validateCity,
    validateCountryV2,
    validateInputKey,
    validateName,
    validateNotes,
    validatePostalCode,
    validateProvince,
    validateTax,
} from '../../utils/RegexHelper'
import { setEmails } from '../../redux/state-slices/EmailSlice'

export default function CustomersNew({
    callback,
    onCancel,
}: {
    callback?: (customer: any, update: boolean) => void
    onCancel?: () => void
}) {
    const dispatch = useAppDispatch()
    const state = useAppSelector((state) => state)
    const navigate = useNavigate()

    const customerRepository = new CustomersRepository()
    const taxRepository = new TaxRepository()

    const [newTax, setNewTax] = useState(false)
    const [validations, setValidations] = useState({
        first_name: { value: '', validated: true },
        last_name: { value: '', validated: true },
        address: { value: '', validated: true },
        city: { value: '', validated: true },
        province: { value: '', validated: true },
        postal: { value: '', validated: true },
        country: { value: '', validated: true },
        tax: { value: 0, validated: true },
        notes: { value: '', validated: true },
    })

    const taxesQuery = useQuery(['taxes', newTax], taxRepository.getTaxes)
    const taxes = taxesQuery.data?.data

    const createCustomerMutation = useMutation({
        mutationFn: () =>
            customerRepository.createCustomer(
                validations.first_name.value,
                validations.last_name.value,
                state.customer.emails,
                validations.address.value,
                validations.city.value,
                validations.province.value,
                validations.postal.value,
                validations.country.value,
                validations.tax.value,
                validations.notes.value
            ),
        onError: (err: any) => {
            if (err.data.message) dispatch(setError([err.data.message]))
            else
                dispatch(
                    setError([
                        'An error has occured. If this keeps happening, please contact support',
                    ])
                )
        },

        onSuccess: (data) => {
            if (callback) callback(data.data, true)
            else navigate(`/invoicing/customers/${data?.data?.id}`)

            dispatch(setSuccess(['Successfully created new customer']))
        },
    })

    const handleTaxChange = (data: any) => {
        setNewTax(false)
        validate([
            {
                value: typeof data == 'object' ? data.id : data,
                f: validateTax,
                name: 'tax',
            },
        ])

        console.log(validations.tax.value)

        // if (typeof data == 'object') {
        //     setTax(parseInt(data.id))
        //     console.log('tax data is object')
        // }
        // if (typeof data == 'string') {
        //     setTax(parseInt(data))
        //     console.log('tax data is string')
        // }
    }

    const validate = (data: Paywell.API.V2.ValidationData[]) => {
        data.forEach((v) => {
            setValidations((prevValidations) => ({
                ...prevValidations,
                [v.name]: {
                    value: v.value,
                    validated:
                        v.name === 'first_name' || v.name === 'last_name'
                            ? v.value && v.value.length >= 2
                            : v.value && v.value.length > 2
                            ? v.f(v.value)
                            : true,
                },
            }))
        })
    }

    /**
     *
     */
    const handleClose = () => {
        setNewTax(false)
        validate([
            {
                value: -1,
                f: validateTax,
                name: 'tax',
            },
        ])
    }
    /**
     *
     */
    const handleCancel = (e: any) => {
        if (onCancel) {
            e.preventDefault()
            onCancel()
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        createCustomerMutation.mutate()
    }

    return (
        <>
            <TaxModal
                show={newTax}
                onClose={handleClose}
                callback={handleTaxChange}
            />

            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="sm:flex ">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">
                            New Customer
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Add a new Customer
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Link
                                    onClick={(e) => handleCancel(e)}
                                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                                    to={'/invoicing/customers'}
                                >
                                    Cancel
                                </Link>
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-sky-600 focus:ring-offset-2"
                                    disabled={createCustomerMutation.isLoading}
                                >
                                    {createCustomerMutation.isLoading ? (
                                        <Loading height="6" width="6" />
                                    ) : (
                                        'Create'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                                        Personal Information{' '}
                                    </h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        Use a permanent address where you can
                                        receive mail.
                                    </p>
                                </div>
                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="first-name"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Full Name
                                        </label>

                                        <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="first-name"
                                                id="first-name"
                                                placeholder="First name"
                                                className={`block w-full max-w-lg rounded-md shadow-sm sm:max-w-xs sm:text-sm
                                                ${
                                                    validations.first_name
                                                        .validated
                                                        ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                        : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                }`}
                                                onKeyUp={(e) =>
                                                    validate([
                                                        {
                                                            value: (
                                                                e.target as HTMLInputElement
                                                            ).value,
                                                            f: (value) =>
                                                                value.length >=
                                                                2,
                                                            name: 'first_name',
                                                        },
                                                    ])
                                                }
                                                required
                                            />
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="last-name"
                                                id="last-name"
                                                placeholder="Last name"
                                                className={`block w-full max-w-lg rounded-md sm:max-w-xs sm:text-sm
                                                ${
                                                    validations.last_name
                                                        .validated
                                                        ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                        : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                }`}
                                                onKeyUp={(e) =>
                                                    validate([
                                                        {
                                                            value: (
                                                                e.target as HTMLInputElement
                                                            ).value,
                                                            f: (value) =>
                                                                value.length >=
                                                                2,
                                                            name: 'last_name',
                                                        },
                                                    ])
                                                }
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Email address
                                        </label>

                                        <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                            <Email />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="country"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Address
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0 flex flex-col gap-4">
                                            {/* validateAddress */}
                                            <div className="flex gap-4">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="street-address"
                                                    id="street-address"
                                                    placeholder="Street address"
                                                    className={`block w-full max-w-lg rounded-md shadow-sm sm:text-sm
                                                    ${
                                                        validations.address
                                                            .validated
                                                            ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                            : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                    }`}
                                                    title="Please enter a valid street address"
                                                    onKeyUp={(e) =>
                                                        validateInputKey(
                                                            e.key,
                                                            (
                                                                e.target as HTMLInputElement
                                                            ).value
                                                        ) &&
                                                        validate([
                                                            {
                                                                value: (
                                                                    e.target as HTMLInputElement
                                                                ).value,
                                                                f: validateAddress,
                                                                name: 'address',
                                                            },
                                                        ])
                                                    }
                                                    required
                                                />
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="city"
                                                    id="city"
                                                    placeholder="City"
                                                    className={`block w-full max-w-lg rounded-md sm:text-sm
                                                    ${
                                                        validations.city
                                                            .validated
                                                            ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                            : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                    }`}
                                                    pattern="^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                                                    title="Please enter a valid city"
                                                    onKeyUp={(e) =>
                                                        validateInputKey(
                                                            e.key,
                                                            (
                                                                e.target as HTMLInputElement
                                                            ).value
                                                        ) &&
                                                        validate([
                                                            {
                                                                value: (
                                                                    e.target as HTMLInputElement
                                                                ).value,
                                                                f: validateCity,
                                                                name: 'city',
                                                            },
                                                        ])
                                                    }
                                                    required
                                                />
                                            </div>
                                            <div className="flex gap-4">
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="state-province"
                                                    id="state-province"
                                                    placeholder="State/Province"
                                                    className={`block w-full max-w-lg rounded-md shadow-sm sm:text-sm
                                                    ${
                                                        validations.province
                                                            .validated
                                                            ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                            : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                    }`}
                                                    pattern="^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                                                    title="Please enter a valid province / state"
                                                    onKeyUp={(e) =>
                                                        validateInputKey(
                                                            e.key,
                                                            (
                                                                e.target as HTMLInputElement
                                                            ).value
                                                        ) &&
                                                        validate([
                                                            {
                                                                value: (
                                                                    e.target as HTMLInputElement
                                                                ).value,
                                                                f: validateProvince,
                                                                name: 'province',
                                                            },
                                                        ])
                                                    }
                                                    required
                                                />
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="postal-code"
                                                    id="postal-code"
                                                    placeholder="Postal Code"
                                                    className={`block w-full max-w-lg rounded-md
                                                    ${
                                                        validations.postal
                                                            .validated
                                                            ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                            : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                    }`}
                                                    pattern="^[a-zA-Z0-9]+$"
                                                    title="Please enter a valid postal code"
                                                    onKeyUp={(e) =>
                                                        validateInputKey(
                                                            e.key,
                                                            (
                                                                e.target as HTMLInputElement
                                                            ).value
                                                        ) &&
                                                        validate([
                                                            {
                                                                value: (
                                                                    e.target as HTMLInputElement
                                                                ).value,
                                                                f: validatePostalCode,
                                                                name: 'postal',
                                                            },
                                                        ])
                                                    }
                                                    required
                                                />
                                                <select
                                                    id="country"
                                                    name="country"
                                                    className={`mt-1 block w-full rounded-md py-2 pl-3 pr-10 text-base sm:text-sm
                                                    ${
                                                        validations.country
                                                            .validated
                                                            ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                            : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                    }`}
                                                    onChange={(e) =>
                                                        validate([
                                                            {
                                                                value: e.target
                                                                    .value,
                                                                f: validateCountryV2,
                                                                name: 'country',
                                                            },
                                                        ])
                                                    }
                                                    required
                                                >
                                                    <option>
                                                        Select Country
                                                    </option>
                                                    <option value="USA">
                                                        United States
                                                    </option>
                                                    <option value="CAD">
                                                        Canada
                                                    </option>
                                                    <option value="MEX">
                                                        Mexico
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-y-8 divide-y divide-gray-200 ">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 ">
                        <div className="space-y-6 sm:space-y-5 ">
                            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10 ">
                                <div className="sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                                        Other Information{' '}
                                    </h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        Fill out their tax rates and any notes{' '}
                                    </p>
                                </div>
                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="first-name"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Tax Rate
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                            <select
                                                value={validations.tax.value}
                                                id="tax-rates"
                                                name="tax-rates"
                                                className={`mt-1 block rounded-md py-2 pl-3 pr-10 text-base sm:text-sm
                                                ${
                                                    validations.tax.validated
                                                        ? 'border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm'
                                                        : 'border-red-600 shadow-sm focus:border-red-600 focus:ring-red-600 sm:text-sm'
                                                }`}
                                                onChange={(e) => {
                                                    parseInt(e.target.value) ==
                                                    -2
                                                        ? setNewTax(true)
                                                        : handleTaxChange(
                                                              e.target.value
                                                          )
                                                }}
                                                required
                                            >
                                                <option value="-1">
                                                    Select Tax
                                                </option>
                                                <option value="-2">
                                                    New Tax
                                                </option>
                                                {taxes &&
                                                    taxes.map((t, index) => (
                                                        <option
                                                            value={t.id}
                                                            key={t.id}
                                                        >
                                                            {t.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label
                                            htmlFor="first-name"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            Notes
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0 flex gap-4">
                                            <textarea
                                                rows={4}
                                                name="comment"
                                                id="comment"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sky-600 focus:ring-sky-600 sm:text-sm"
                                                defaultValue={''}
                                                onKeyUp={(e) =>
                                                    validate([
                                                        {
                                                            value: (
                                                                e.target as HTMLInputElement
                                                            ).value,
                                                            f: validateNotes,
                                                            name: 'notes',
                                                        },
                                                    ])
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
