import { useNavigate } from 'react-router-dom'
import React from 'react'
import Decimal from 'decimal.js'

interface Props {
    userCopy: Paywell.API.V2.User | null
    isAdmin: boolean
}
export default function UserFees({ userCopy, isAdmin }: Props) {
    const userIsAdminOrAgent =
        userCopy?.user_type_id === 2 || userCopy?.user_type_id === 3
    const navigate = useNavigate()
    return (
        <div className={`p-10`}>
            {isAdmin && (
                <div className="mb-2 flex justify-end">
                    <button
                        onClick={() =>
                            navigate('/users/' + userCopy?.id + '/fees/edit')
                        }
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Edit Page
                    </button>
                </div>
            )}
            <div className={'border border-gray-100 rounded-md p-2'}>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Fees
                    </h3>
                </div>
                <div className="mt-6 border-t border-gray-100 grid grid-cols-2 gap-2">
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Charge per transaction
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    ¢{userCopy?.tx}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    MDR rate
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.mdr} %
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Request for eTransfer charge per transaction
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    ¢{userCopy?.request_money_tx_fee}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Request for eTransfer MDR
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    ¢{userCopy?.request_money_mdr_fee}
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Minimum MDR
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    ${userCopy?.min} a month
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <div>
                        <dl className="divide-y divide-gray-100">
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Batch outs
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    ${userCopy?.settlement} each
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Settlement hold fee
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.settlement_hold_fee} BPs
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Split banking fee
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.split_banking_fee} BPs
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Monthly Statement
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    ${userCopy?.statement} a month
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Equipment Rental
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    ${userCopy?.rental} a month
                                </dd>
                            </div>
                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                <dt className="text-sm font-medium leading-6 text-gray-900">
                                    Tax Exempt
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {userCopy?.tax_exempt ? 'Yes' : 'No'}
                                </dd>
                            </div>
                            {userIsAdminOrAgent && (
                                <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Agent Commission
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {userCopy?.agent_commission
                                            ? new Decimal(
                                                  userCopy.agent_commission
                                              )
                                                  .mul(100)
                                                  .toString()
                                            : ''}
                                        %
                                    </dd>
                                </div>
                            )}
                            {userIsAdminOrAgent && (
                                <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Agent Override
                                    </dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {userCopy?.agent_override
                                            ? new Decimal(
                                                  userCopy.agent_override
                                              )
                                                  .mul(100)
                                                  .toString()
                                            : ''}
                                        %
                                    </dd>
                                </div>
                            )}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}
